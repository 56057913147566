import { useEffect } from 'react';
import { store } from '@redux/store';
import { Features, setExpirationDate, setIsLoggedIn, setLoggedInUserData } from '@redux/reducers/slices/user';
import { getOrRefreshToken, getTokenFromStorage, isTokenObject, TokenType } from '@services/hooks/authentication/utils';

export function useToken() {
  const isTokenRefreshing = sessionStorage.getItem(`refreshing`);
  const tokenData = getTokenFromStorage();
  const tokenValue = tokenData?.token;
  const expires = tokenData?.expires;

  useEffect(() => {
    getOrRefreshToken();
  }, []);

  useEffect(() => {
    const token = { token: tokenValue, expires };
    if (isTokenRefreshing) {
      return;
    }
    if (isTokenObject(token)) {
      const expirationDate = token.expires;
      const isExpired = Number(expirationDate) < new Date().getTime();
      store.dispatch(setExpirationDate(expirationDate));
      store.dispatch(setIsLoggedIn(!isExpired));

      const decodedTokenStringData = window.atob(encodeURIComponent(token.token).split(`.`)[1]);
      const parsedJwtToken = JSON.parse(decodedTokenStringData) as TokenType;

      store.dispatch(
        setLoggedInUserData({
          userId: Number(parsedJwtToken.userId),
          partnerId: isNaN(Number(parsedJwtToken.partnerId)) ? null : Number(parsedJwtToken.partnerId),
          userType: parsedJwtToken.userType,
          enabledFeatures: parsedJwtToken.enabledFeatures?.split(`,`) as Features[],
          firstName: parsedJwtToken.userFirstname,
          lastName: parsedJwtToken.userLastname,
          isAdmin: parsedJwtToken.isAdminUser === `True`,
        }),
      );
    }
  }, [isTokenRefreshing, tokenValue, expires]);
}

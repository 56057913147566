import { Components, Theme, ThemeOptions, createTheme, PaletteColorOptions, TypeAction } from '@mui/material/styles';
import { SliderThumb } from '@components/controls/react-hook-form-friendly/dumb/Slider/SliderThumb';
import { LinkBehavior } from './LinkBehaviour';

const fontFamilyNormal = `'Gotham Book', Arial, Helvetica, sans-serif`;
const fontFamilyBold = `'Gotham Bold', Arial, Helvetica, sans-serif`;
export const fontFamilyMedium = `'Gotham Medium', Arial, Helvetica, sans-serif`;
const fontFamilyBook = fontFamilyNormal;
const fontWeightBold = 700;
const buttonBorderWidth = 2;
const avatarButtonSize = 64;
export const buttonMediumSize = 48;
export const buttonSmallSize = 32;

export const rexelEnergy = {
  selectedBlue: `#002543`,
  rexelBlue: `#0a287d`,
  ctaBlue: `#0072ce`,
  blackBlue: `#0f1923`,
  grey: `#757575`,
  lightGrey: `#b6b9bc`,
  hover: `#00a3ad`,
  green: `#4ba55a`,
  yellow: `#ffcd00`,
  red: `#c8102e`,
  brewd: `#7b6469`,
  brown: `#c5b9ac`,
  lightBrown: `#ede9e5`,
  divider: `#e5e5e5`,
  beige: `#f5f5f5`,
  lightBase: `#d7d7d7`,
};

// eslint-disable-next-line quotes
declare module '@mui/material/styles' {
  interface ThemeOptions {
    icons: {
      size: {
        normal: number;
        small: number;
        large: number;
      };
    };
  }
  interface Theme {
    icons: {
      size: {
        normal: number;
        small: number;
        large: number;
      };
    };
  }

  interface Palette {
    rexelEnergy: typeof rexelEnergy;
  }

  interface PaletteOptions {
    rexelEnergy: typeof rexelEnergy;
  }
}

const white = `#fff`;

const primary: PaletteColorOptions = {
  light: `#dce8f4`,
  main: rexelEnergy.ctaBlue,
  dark: rexelEnergy.selectedBlue,
  contrastText: white,
};

export const background = { default: `#f6f7f9`, paper: white };
const success = { main: `#dbedde`, dark: rexelEnergy.green };
const warning = { main: `#fff5cc`, dark: rexelEnergy.yellow };
const error = { main: `#f4cfd5`, dark: rexelEnergy.red };
const info = { main: `#cce3f5`, dark: rexelEnergy.ctaBlue };
const action: Partial<TypeAction> = { disabled: white, disabledBackground: rexelEnergy.lightGrey };
const divider = rexelEnergy.divider;
const text = { primary: rexelEnergy.blackBlue, secondary: rexelEnergy.grey };

const componentsOverride = (theme: Theme): Components => ({
  MuiTooltip: {
    defaultProps: { PopperProps: { modifiers: [{ name: `offset`, options: { offset: [0, -20] } }] } },
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: `1px solid ${theme.palette.rexelEnergy.lightGrey}`,
        boxShadow: theme.shadows[3],
        maxWidth: 320,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
      LinkComponent: LinkBehavior,
    },
  },
  MuiIconButton: {
    defaultProps: {
      color: `primary`,
    },
    styleOverrides: {
      sizeSmall: {
        minHeight: buttonSmallSize,
      },
      sizeMedium: {
        minHeight: buttonMediumSize,
      },
      root: {
        aspectRatio: `1/1`,

        borderRadius: 0,
        '&.Mui-disabled': {
          color: theme.palette.rexelEnergy.lightGrey,
        },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.primary.light,

          '& svg': {
            color: theme.palette.primary.dark,
          },
        },
      },
      colorPrimary: {
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.dark,

          '& svg': {
            color: theme.palette.common.white,
          },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: 48,
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      disableAnimation: true,
      shrink: false,
    },
    styleOverrides: {
      root: {
        color: theme.palette.common.black,
        '&.Mui-focused': { color: theme.palette.common.black },
        '&.Mui-error': { color: theme.palette.error.dark },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          backgroundColor: `white`,
          border: `var(--border-grey-single)`,
          transition: `border 0.2s ease-in`,
          '&:hover': { border: `1px solid var(--color-blue-selected)` },
          '& fieldset': { display: `none` },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 1,
        padding: 0,
        border: `1px solid ${theme.palette.rexelEnergy.blackBlue}`,
        transition: `border-color 0.2s ease-in-out`,
        minHeight: `26px`,
        minWidth: `26px`,
        '& svg': {
          backgroundColor: `transparent`,
          transform: `scale(0)`,
          color: theme.palette.common.white,
        },
        '&:hover, &.Mui-focusVisible': {
          border: `1px solid ${theme.palette.rexelEnergy.blackBlue}`,
          backgroundColor: theme.palette.rexelEnergy.blackBlue,
        },
        '&.Mui-checked': {
          backgroundColor: theme.palette.rexelEnergy.blackBlue,
          '& svg': {
            transform: `scale(1)`,
            color: theme.palette.common.white,
          },
        },
        '&.Mui-disabled': {
          color: theme.palette.rexelEnergy.lightGrey,
          backgroundColor: theme.palette.rexelEnergy.beige,
          '& svg': {
            transform: `scale(1)`,
            color: theme.palette.rexelEnergy.grey,
          },
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: theme.spacing(6),
        height: theme.spacing(3.25),
        padding: 0,
        margin: 0,
      },
      switchBase: {
        padding: 0,
        margin: 2,
        transitionDuration: `0.1s`,
        color: theme.palette.common.white,
        '&.Mui-checked': {
          color: theme.palette.common.white,
          transform: `translateX(22px)`,
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.rexelEnergy.selectedBlue,
          },
          '&.Mui-disabled': {
            opacity: 0.6,
            color: theme.palette.common.white,

            '& + .MuiSwitch-track': {
              opacity: 0.6,
            },
          },
        },
        '& .MuiSwitch-input:focus-visible + .MuiSwitch-thumb': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      track: {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.rexelEnergy.lightGrey,
        opacity: 1,
        transition: theme.transitions.create([`background-color`], {
          duration: 500,
        }),
      },
      thumb: {
        boxSizing: `border-box`,
        width: 22,
        height: 22,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: `outlined`,
      InputLabelProps: {
        shrink: false,
      },
    },
    styleOverrides: {
      root: {
        '& label': {
          transform: `none`,
          position: `static`,
        },
        '& input': {
          padding: theme.spacing(1.75, 2),
          backgroundColor: theme.palette.common.white,
          border: `none`,
          height: theme.spacing(2.25),
        },
        '& .MuiInputBase-root.Mui-disabled': {
          border: `1px solid ${theme.palette.rexelEnergy.lightGrey}`,
        },
        '& label.Mui-disabled ': {
          color: theme.palette.common.black,
        },
        '& .MuiInputBase-root.Mui-disabled input': {
          backgroundColor: theme.palette.rexelEnergy.beige,
        },
        '&:hover input': {
          border: `none`,
        },
        '& fieldset': {
          borderColor: theme.palette.rexelEnergy.lightGrey,
        },
        '& .MuiInputBase-root.Mui-focused fieldset': {
          borderColor: theme.palette.common.black,
          borderWidth: 1,
        },
        '& .MuiInputBase-root.Mui-error fieldset': {
          borderColor: theme.palette.error.dark,
          borderWidth: 1,
        },
        '& .MuiInputBase-sizeSmall': {
          width: 200,
        },
        '& .MuiFormHelperText-root': {
          paddingLeft: 0,
          marginLeft: 0,
          color: rexelEnergy.blackBlue,
        },
        '& .MuiFormHelperText-root.Mui-error': {
          paddingLeft: 0,
          marginLeft: 0,
          color: rexelEnergy.red,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: theme.palette.rexelEnergy.red,
      },
    },
  },
  MuiSlider: {
    defaultProps: {
      slots: { thumb: SliderThumb },
    },
    styleOverrides: {
      root: {
        width: `calc(100% - ${theme.spacing(4)})`,
        marginLeft: theme.spacing(2),
      },
      trackInverted: {
        '& .MuiSlider-track': {
          backgroundColor: theme.palette.rexelEnergy.lightGrey,
          border: `none`,
        },
        '& .MuiSlider-rail': {
          backgroundColor: theme.palette.rexelEnergy.ctaBlue,
          border: `none`,
        },
      },
      thumb: {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.rexelEnergy.ctaBlue}`,
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),

        '&:hover, &:focus, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: `none`,
        },
      },
      rail: {
        backgroundColor: theme.palette.rexelEnergy.grey,
        height: theme.spacing(0.5),
        border: `none`,
        width: `calc(100% + ${theme.spacing(4)})`,
        marginLeft: theme.spacing(-2),
      },
      track: {
        height: theme.spacing(0.5),
        border: `none`,
        width: `calc(100% + ${theme.spacing(2)})`,
        marginLeft: theme.spacing(-2),
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: `contained`,
      disableElevation: true,
    },
    styleOverrides: {
      startIcon: {
        marginRight: theme.spacing(1),
      },
      root: {
        textTransform: `none`,
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        height: `fit-content`,
        fontSize: 16,
        fontFamily: fontFamilyMedium,
        lineHeight: 1.5,
        '&.no-break': {
          whiteSpace: `nowrap`,
        },
      },
      sizeMedium: {
        minHeight: buttonMediumSize,
      },
      sizeSmall: {
        minHeight: buttonSmallSize,
        padding: theme.spacing(0.5, 1),
        fontSize: 13,
        fontFamily: fontFamilyNormal,

        '& .MuiButton-startIcon': {
          marginRight: theme.spacing(1),
        },
      },
      textPrimary: {
        color: theme.palette.primary.dark,
        padding: theme.spacing(1, 2),

        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,

          '& svg': {
            color: theme.palette.common.white,
          },

          '&:hover, &.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,

            '& svg': {
              color: theme.palette.common.white,
            },
          },
        },

        '&.Mui-disabled': {
          color: theme.palette.rexelEnergy.grey,

          '& svg': {
            color: theme.palette.rexelEnergy.lightGrey,
          },
        },

        '& svg': {
          color: theme.palette.primary.main,
        },

        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.dark,

          '& svg': {
            color: theme.palette.primary.dark,
          },
        },
      },
      containedPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: white,

        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      outlinedPrimary: {
        border: `${buttonBorderWidth}px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,

        '&:hover, &.Mui-focusVisible': {
          backgroundColor: `transparent`,
          color: theme.palette.primary.dark,
          border: `${buttonBorderWidth}px solid ${theme.palette.primary.dark}`,
        },

        '&: disabled': {
          color: theme.palette.rexelEnergy.lightGrey,
        },
      },
      text: {
        color: theme.palette.primary.dark,
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      elevation: 1,
    },
    styleOverrides: {
      root: {
        '& .MuiList-root': {
          padding: theme.spacing(1, 0),
        },
      },
      paper: {
        transitionDuration: `0.1s !important`, // !important is required to override MUI here 😔
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        textTransform: `none`,
        color: theme.palette.primary.dark,
        padding: theme.spacing(0, 3, 0, 2),
        lineHeight: 2.5,

        '&:hover, &.Mui-focusVisible': {
          backgroundColor: rexelEnergy.beige,
        },

        '& svg': {
          color: theme.palette.primary.main,
        },

        '& .MuiListItemIcon-root': {
          minWidth: `24px`,
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        margin: 0,
        padding: 0,
        justifyContent: `space-between`,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        height: 48,
        width: 24,
        maxWidth: 24,
        minWidth: `24px`,
        margin: `auto 16px auto 0 !important`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        alignSelf: `center`,
      },
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        width: avatarButtonSize,
        height: avatarButtonSize,
        marginRight: theme.spacing(1),

        '& img': {
          width: avatarButtonSize,
          height: avatarButtonSize,
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: `100%`,
        height: `100%`,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        '&.Mui-focusVisible': {
          backgroundColor: rexelEnergy.beige,
        },
        '& .MuiListItemIcon-root': {
          marginLeft: `${theme.spacing(1)} !important`,
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        minHeight: theme.spacing(4),
        padding: 0,
        margin: 0,
      },
      multiline: {
        minHeight: theme.spacing(5),
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        zIndex: 12,
        aspectRatio: `1/1`,
      },
      sizeMedium: {
        minHeight: buttonMediumSize,
      },
      sizeSmall: {
        minHeight: buttonSmallSize,
      },
      secondary: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.background.paper,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      primary: {
        '&.Mui-focusVisible': {
          backgroundColor: theme.palette.rexelEnergy.selectedBlue,
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    },
    styleOverrides: {
      root: {
        color: theme.palette.common.black,
        textDecorationColor: `inherit`,
      },
    },
  },
  MuiCard: {
    defaultProps: {
      variant: `outlined`,
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0, 3),
      },
      content: {
        height: theme.spacing(6),
        alignItems: `center`,
        display: `flex`,
      },
    },
    defaultProps: {
      titleTypographyProps: {
        variant: `h2`,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        margin: theme.spacing(2, 0),
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      transitionDuration: 150,
    },
    styleOverrides: {
      root: {
        '& + .pac-container': {
          zIndex: theme.zIndex.drawer + 1,
        },
      },
      paper: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down(`md`)]: {
          width: `100%`,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 2.5),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(2, 2.5),
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingBottom: theme.spacing(1),
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: `fit-content`,
        fontFamily: fontFamilyMedium,
        lineHeight: 1.5,
        color: theme.palette.primary.dark,
        fontSize: 16,
        '&.Mui-selected': {
          color: theme.palette.primary.dark,
        },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 2,
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        height: theme.spacing(6),
        marginTop: 0,
        backgroundColor: theme.palette.common.white,

        '&.Mui-disabled': {
          backgroundColor: theme.palette.rexelEnergy.beige,
          border: `1px solid ${theme.palette.rexelEnergy.lightGrey}`,
        },

        '&.Mui-disabled fieldset': {
          border: `none`,
        },

        '&.Mui-error fieldset': {
          borderColor: theme.palette.error.dark,
          background: theme.palette.error.main,
        },

        '&.Mui-disabled:hover fieldset': {
          border: `1px solid ${theme.palette.rexelEnergy.lightGrey}`,
        },

        '&.Mui-focused fieldset': {
          borderColor: `${theme.palette.common.black} !important`,
        },

        '&:hover fieldset': {
          borderColor: theme.palette.common.black,
        },

        '& svg': {
          color: theme.palette.rexelEnergy.grey,
        },
        '& fieldset': {
          border: `1px solid ${theme.palette.rexelEnergy.lightGrey}`,
        },
      },
    },
  },
  MuiStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        transform: `none`,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.dark,
      },
    },
  },
});

export const themeOptions: ThemeOptions = {
  palette: { primary, success, warning, error, info, action, rexelEnergy, background, divider, text },
  shape: { borderRadius: 0 },
  typography: {
    fontWeightBold,
    fontFamily: fontFamilyBook,
    allVariants: {
      color: rexelEnergy.blackBlue,
      wordBreak: `break-word`,
    },
    h1: {
      fontSize: `1.625rem`, // 26px
      fontFamily: fontFamilyBold,
      fontWeight: fontWeightBold,
    },
    h2: {
      fontSize: `1.25rem`, // 20px
      lineHeight: 1.2,
      fontFamily: fontFamilyBold,
      fontWeight: fontWeightBold,
    },
    h3: {
      fontSize: `1.125rem`, // 18px
      lineHeight: 1.328,
      fontFamily: fontFamilyMedium,
    },
    h4: {
      fontSize: `1rem`, // 16px
      lineHeight: 1.5,
      fontFamily: fontFamilyMedium,
    },
    h5: {
      fontSize: `0.8125rem`, // 13px
      lineHeight: 1.53846,
      fontFamily: fontFamilyMedium,
    },
    h6: {
      fontSize: `0.6875rem`, // 11px
      lineHeight: 0.8125,
      fontFamily: fontFamilyMedium,
    },
    body2: {
      fontSize: `1rem`, // 16px
      fontFamily: fontFamilyMedium,
    },
    subtitle1: {
      fontSize: `0.8125rem`, // 13px
      color: rexelEnergy.grey,
      lineHeight: 1.538465,
    },
    subtitle2: { fontSize: `0.8125rem`, lineHeight: 1.538465 },
    button: {
      textTransform: `none`,
    },
    caption: {
      fontSize: `1rem`, // 16px
      color: rexelEnergy.grey,
    },
  },
  icons: {
    size: {
      normal: 1,
      small: 0.75,
      large: 1.25,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1200,
      xl: 1536,
    },
  },
};

const theme = createTheme(themeOptions);
theme.components = componentsOverride(theme);

export { theme };

import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { VIEW_TYPES, ViewType, showNext } from '@components/columnLayout/utils';
import { mdiArrowLeft, mdiClose, mdiMenu } from '@mdi/js';
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { setCurrentLeadID } from '@redux/actions/global';
import { AppState } from '@redux/reducers';
import { store } from '@redux/store';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { theme } from 'src/styles/theme/theme';
import { NavLinkItem, NavLinkItemProps } from './NavLinkItem';
import { SelectDealers } from './SelectDealers';
import { Route, useRoutesWithTranslations } from './constants';
import { useFetchDealer } from './useFetchDealer';
import './navbar.css';
import { getPreviousView } from './utils';
import { PageTouchPointWrapper } from '@components/external/TouchPointFeedback/PageTouchPointWrapper';
import { setSelectedPartnerId } from '@redux/reducers/slices/partner';
import { useSignOut } from '@services/api/authenticate/signOut';

type NavbarProps = {
  children: React.ReactNode;
  viewType: ViewType;
  navbarMenuOptions?: React.ReactNode;
  noMenu: boolean;
};

export const Navbar: React.FC<NavbarProps> = ({ children, viewType, navbarMenuOptions, noMenu }) => {
  const { baseRoutes, userSectionRoutes } = useRoutesWithTranslations();
  const currentView = useSelector<AppState, ViewType>((state) => state.Global.currentPageView);
  const isMobile = useSelector<AppState, boolean>((state) => state.Global.isMobileView);
  const currentRoute = useLocation().pathname;
  const [previousRoute, setPreviousState] = useState(currentRoute);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { signOut } = useSignOut();
  useFetchDealer();
  const {
    translate,
    translations: {
      common: { buttons },
    },
  } = useTranslations();

  useEffect(() => {
    if (currentRoute !== previousRoute) {
      setPreviousState(currentRoute);
    }
    setIsDrawerOpen(false);
  }, [currentRoute, previousRoute]);

  const viewHandlerBackCallback = useCallback(() => {
    if (currentView === VIEW_TYPES.INFO) {
      store.dispatch(setCurrentLeadID(-1));
      store.dispatch(setSelectedPartnerId(-1));
    }
    showNext(getPreviousView(currentView))();
  }, [currentView]);

  const isCurrent = useCallback((path: NavLinkItemProps[`path`]) => currentRoute === path, [currentRoute]);

  const getOptionsForRoutes = useCallback(
    (routes: Route[], profileItems = false) =>
      routes.map(({ name, path }) => (
        <NavLinkItem
          key={path}
          name={name}
          path={path}
          active={isCurrent(path)}
          profileItem={profileItems}
          onActivate={() => (isCurrent(path) ? setIsDrawerOpen(false) : undefined)}
        />
      )),
    [isCurrent],
  );

  const toggleMenu = useCallback(() => {
    setIsDrawerOpen((previousValue) => !previousValue);
  }, []);

  const onLogOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const baseOptions = useMemo(() => getOptionsForRoutes(baseRoutes), [baseRoutes, getOptionsForRoutes]);
  const userSectionOptions = useMemo(
    () => getOptionsForRoutes(userSectionRoutes, true),
    [getOptionsForRoutes, userSectionRoutes],
  );
  const currentOption = useMemo(
    () => getOptionsForRoutes([...baseRoutes, ...userSectionRoutes].filter(({ path }) => isCurrent(path))),
    [baseRoutes, getOptionsForRoutes, isCurrent, userSectionRoutes],
  );

  const leftElement = useMemo(() => {
    if (noMenu) {
      return null;
    }
    switch (viewType) {
      case VIEW_TYPES.LIST:
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton onClick={toggleMenu} sx={{ color: theme.palette.primary.dark }}>
              <MdiIconWrapper path={mdiMenu} />
            </IconButton>
            <div className="nav-options">{currentOption}</div>
          </Stack>
        );
      case VIEW_TYPES.INFO:
        return (
          <IconButton className="go-back-button" onClick={viewHandlerBackCallback}>
            <MdiIconWrapper path={mdiArrowLeft} />
          </IconButton>
        );
      case VIEW_TYPES.EDIT:
        return null;
      default:
        return null;
    }
  }, [currentOption, noMenu, toggleMenu, viewHandlerBackCallback, viewType]);

  const middleElement = useMemo(() => {
    if (noMenu) {
      return null;
    }
    switch (viewType) {
      case VIEW_TYPES.INFO:
        return <div className="nav-options">{currentOption}</div>;
      case VIEW_TYPES.EDIT:
        return (
          <Typography variant="h1" component="h2">
            {translate(buttons.change)}
          </Typography>
        );
      default:
        return <div className="nav-options" />;
    }
  }, [currentOption, noMenu, viewType, translate, buttons.change]);

  const rightElement = useMemo(() => {
    if (noMenu) {
      return null;
    }
    switch (viewType) {
      case VIEW_TYPES.LIST:
        return <div className="row">{navbarMenuOptions}</div>;
      case VIEW_TYPES.INFO:
        return <div>{navbarMenuOptions}</div>;
      case VIEW_TYPES.EDIT:
      default:
        return null;
    }
    // TODO: check full list of useEffect dependencies to add or remove
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarMenuOptions, noMenu, toggleMenu, viewHandlerBackCallback, viewType]);

  const navbarContent = useMemo(
    () =>
      (viewType === VIEW_TYPES.LIST && (
        <Box className="drawer-content" sx={{ padding: 2 }}>
          <Stack alignItems="end">
            <IconButton
              className="menu-item close"
              onClick={toggleMenu}
              sx={{ color: theme.palette.primary.dark, marginBlockEnd: 1 }}
            >
              <MdiIconWrapper path={mdiClose} />
            </IconButton>
          </Stack>
          <Stack className="nav-options nav-options-base">
            <SelectDealers />
          </Stack>
          <Stack className="nav-options nav-options-base" sx={{ marginBlockStart: 3 }}>
            {baseOptions}
          </Stack>

          <Stack className="nav-options" sx={{ marginBlockStart: 3 }}>
            {userSectionOptions}
            <Button
              sx={{ justifyContent: `flex-start` }}
              variant="text"
              className="nav-item profile-item"
              onClick={onLogOut}
            >
              {translate(buttons.logOut)}
            </Button>
          </Stack>
        </Box>
      )) ||
      null,
    [viewType, baseOptions, userSectionOptions, onLogOut, toggleMenu, translate, buttons.logOut],
  );

  return (
    <div className={`column gap ${viewType === VIEW_TYPES.EDIT && `fh`}`}>
      {!noMenu && (viewType === VIEW_TYPES.LIST || isMobile) && (
        <nav className="navbar">
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            {viewType === VIEW_TYPES.INFO ? (
              <Stack direction="row" spacing={1} alignItems="center">
                {leftElement}
                {middleElement}
              </Stack>
            ) : (
              <>
                {leftElement}
                {middleElement}
              </>
            )}
            {rightElement}
          </Stack>
          <Drawer open={isDrawerOpen} onClose={toggleMenu} anchor="left">
            {navbarContent}
            <PageTouchPointWrapper />
          </Drawer>
        </nav>
      )}
      {children}
    </div>
  );
};

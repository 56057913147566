import { JsonWebToken, UserType } from '@generatedTypes/data-contracts';
import { store } from '@redux/store';
import { setExpirationDate, setIsLoggedIn } from '@redux/reducers/slices/user';
import { refresh } from '@services/api/authenticate/refresh';

const TOKEN_EXPIRATION_THRESHOLD = 1000 * 60 * 5; // 5 minutes

export type TokenType = {
  jti: string;
  userId: string;
  userType: UserType;
  isAdminUser: string;
  partnerId: string;
  userFirstname: string;
  userLastname: string;
  enabledFeatures: string;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
};

export type TokenObject = {
  token: string;
  expires: number;
};

export const getOrRefreshToken = async (): Promise<TokenObject | null> => {
  const token = getTokenFromStorage();
  const isRefreshing = sessionStorage.getItem(`refreshing`);
  if (isRefreshing) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getOrRefreshToken());
      }, 100);
    });
  }

  if (!token) {
    sessionStorage.setItem(`refreshing`, `true`);
    const newToken = await refresh();
    sessionStorage.removeItem(`refreshing`);
    return saveTokenToStorage(newToken);
  }

  return token;
};

export const getTokenFromStorage = (): TokenObject | null => {
  const tokenString = sessionStorage.getItem(`token`);
  const expiryTimeString = sessionStorage.getItem(`expires`);
  const expiryTime = expiryTimeString ? Number(expiryTimeString) : 0;
  const isRefreshing = sessionStorage.getItem(`refreshing`);
  const isExpired = expiryTime < new Date().getTime();
  const isCloseToExpiration = expiryTime - new Date().getTime() < TOKEN_EXPIRATION_THRESHOLD;

  if (tokenString && expiryTimeString && !isExpired && isCloseToExpiration && !isRefreshing) {
    sessionStorage.setItem(`refreshing`, `true`);
    refresh().then((newToken) => {
      sessionStorage.removeItem(`refreshing`);
      saveTokenToStorage(newToken);
    });
  }

  if (isExpired) {
    return null;
  }

  return tokenString && expiryTime
    ? {
        token: tokenString,
        expires: expiryTime,
      }
    : null;
};

export const saveTokenToStorage = (loginResponse: JsonWebToken | null) => {
  const tokenObject = loginResponse ? parseJsonWebTokenToTokenObject(loginResponse) : null;
  if (isTokenObject(tokenObject)) {
    store.dispatch(setIsLoggedIn(true));
    store.dispatch(setExpirationDate(tokenObject.expires));
    sessionStorage.setItem(`token`, tokenObject.token);
    sessionStorage.setItem(`expires`, JSON.stringify(tokenObject.expires));
    return tokenObject;
  } else {
    resetTokenInStorage();
    return null;
  }
};

export const resetTokenInStorage = () => {
  store.dispatch(setIsLoggedIn(false));
  store.dispatch(setExpirationDate(null));
  sessionStorage.removeItem(`token`);
  sessionStorage.removeItem(`expires`);
};

export const parseJsonWebTokenToTokenObject = (jsonToken: JsonWebToken): TokenObject | null => {
  let newTokenObject = null;
  if (jsonToken && typeof jsonToken === `object`) {
    newTokenObject = {
      token: jsonToken?.accessToken ?? ``,
      expires: Number(jsonToken?.accessTokenExpirationTime || NaN) * 1000,
    };
  }
  return isTokenObject(newTokenObject) ? newTokenObject : null;
};

export function isTokenObject(tokenObject: unknown): tokenObject is TokenObject {
  return (
    !!tokenObject &&
    typeof tokenObject === `object` &&
    Object.hasOwn(tokenObject, `token`) &&
    Object.hasOwn(tokenObject, `expires`) &&
    typeof (tokenObject as TokenObject).token === `string` &&
    (tokenObject as TokenObject).token !== `` &&
    typeof (tokenObject as TokenObject).expires === `number` &&
    !isNaN((tokenObject as TokenObject).expires)
  );
}

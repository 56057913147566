import { SystemMessagesForm } from '@pages/systemSettings/messages/SystemMessagesForm';
import { useGetSystemMessage, useUpdateSystemMessage } from '@services/api/systemMessage/systemMessage';

type SystemMessagesFormProps = {
  onClose: () => void;
};

export const SystemMessagesFormWrapper: React.FC<SystemMessagesFormProps> = ({ onClose }) => {
  const { systemMessage } = useGetSystemMessage();
  const { updateSystemMessageAsync } = useUpdateSystemMessage({ onSuccess: onClose });

  return <SystemMessagesForm systemMessage={systemMessage} onCancel={onClose} onSubmit={updateSystemMessageAsync} />;
};

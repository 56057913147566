import { authFetch } from '@services/api/utils';
import { SystemMessageDto, UpdateSystemMessageRequest } from '@generatedTypes/data-contracts';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { SYSTEM_MESSAGE } from '@variables/queryKeys';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useQueryClient } from 'react-query';

export const getSystemMessage = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/system-message`, {
    method: `GET`,
    mode: `cors`,
  });

  const json = await response.json();
  if (response.ok) {
    return json as SystemMessageDto;
  }
  throw new Error(json.message);
};

export const updateSystemMessage = async (data: UpdateSystemMessageRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/system-message`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to update system message`);
  }
};

export const activateSystemMessage = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/system-message/activate`, {
    method: `PUT`,
    mode: `cors`,
  });

  if (!response.ok) {
    throw new Error(`Failed to activate system message`);
  }
};

export const deactivateSystemMessage = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/system-message/deactivate`, {
    method: `PUT`,
    mode: `cors`,
  });

  if (!response.ok) {
    throw new Error(`Failed to deactivate system message`);
  }
};

export const useGetSystemMessage = () => {
  const { data, refetch } = useCustomQuery<SystemMessageDto>({
    queryKey: [SYSTEM_MESSAGE],
    queryFn: getSystemMessage,
  });

  return { systemMessage: data, refetchSystemMessage: refetch };
};

export const useUpdateSystemMessage = (options?: { onSuccess?: () => void }) => {
  const { isLoading, mutate, mutateAsync } = useCustomMutation({
    mutationFn: updateSystemMessage,
    onSuccess: () => {
      options?.onSuccess?.();
    },
  });

  return {
    updateSystemMessage: mutate,
    updateSystemMessageAsync: mutateAsync,
    isLoadingSystemMessageUpdate: isLoading,
  };
};

export const useSetSystemMessageActive = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: (active: boolean) => {
      if (active) {
        return activateSystemMessage();
      }
      return deactivateSystemMessage();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(SYSTEM_MESSAGE);
      options?.onSuccess?.();
    },
  });

  return { setIsActive: mutate, setIsActiveAsync: mutateAsync, isLoading };
};

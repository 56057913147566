import { combineReducers } from 'redux';
import { ValueOf } from '@utils/typeUtils';
import { ACTION_TYPES } from '../actions';
import { globalReducer } from './global';
import { leadReducer } from './lead';
import { leadsReducer } from './leads';
import { UsersReducer } from './users';
import { offerPageReducer } from './offerPage';
import { DealersReducer } from './dealers';
import { dealerUserReducer } from './slices/dealerUser';
import { leadsPageReducer } from './slices/leadsPage';
import { userReducer } from './slices/user';
import { solarEnergyProjectPageReducer } from './slices/solarEnergyProjectPage';
import { partnerReducer } from '@redux/reducers/slices/partner';
import { offerTabReducer } from '@redux/reducers/slices/offerTab';
import { productManagementReducer } from '@redux/reducers/slices/productManagement';
import { systemSettingsReducer } from '@redux/reducers/slices/systemSettings';

export type TAction<T extends Partial<typeof ACTION_TYPES>, P = Record<string, unknown>> = {
  type: ValueOf<T>;
  payload: Partial<P>;
};

export const rootReducer = combineReducers({
  User: userReducer,
  Global: globalReducer,
  Lead: leadReducer,
  Leads: leadsReducer,
  Partner: partnerReducer,
  Users: UsersReducer,
  OfferPage: offerPageReducer,
  Dealers: DealersReducer,
  DealerUserReducer: dealerUserReducer,
  leadsPageReducer,
  solarEnergyProjectPageReducer,
  offerTabReducer,
  ProductManagement: productManagementReducer,
  systemSettingsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

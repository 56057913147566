import { JsonWebToken } from '@generatedTypes/data-contracts';

export async function refresh() {
  return fetch(`${process.env.PROTECTED_API_URL}/Authenticate/refresh`, {
    method: `POST`,
    mode: `cors`,
    headers: {
      'Content-Type': `application/json`,
    },
    body: null,
    credentials: `include`,
  })
    .then(async (res) => {
      if (res.ok) {
        return (await res.json()) as JsonWebToken;
      }
      if (res.status === 401) {
        return null;
      }
      const json = await res.json();
      throw json;
    })
    .catch((err) => {
      throw new Error(err.errors[0].message);
    });
}

import { useToggle } from '@hooks/useToggle';
import Drawer from '@mui/material/Drawer';
import { ProjectRow } from '@pages/NewLeads/Projects/ProjectRow/projectRow';
import {
  setCurrentOfferBidId,
  setCurrentProductCategoryId,
  setCurrentProjectAdditionId,
  setCurrentProjectDeductionId,
  setCurrentProjectId,
  setCurrentProjectProductId,
  setCurrentProjectProductProductId,
} from '@redux/actions/lead';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { EditProjectProductWrapper } from './EditProjectProductWrapper';
import { AddEditProjectAdditionWrapper } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectAdditions/Forms/EditForm/AddEditProjectAdditionWrapper';
import { Empty } from '@components/Empty/Empty';
import NoProjectsImage from '@assets/svg/EmptyComponentIcons/NoProjects.svg';
import { ProductCategoriesList } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productCategoriesList';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { CenteredCircularProgress } from '@components/Loading/CenteredCircularProgress';
import { ProjectAdditionTemplateSelectionWrapper } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectAdditions/Forms/TemplateSelection/ProjectAdditionTemplateSelectionWrapper';
import { selectCurrentProjectId } from '@redux/selectors/lead';
import { ProjectDeductionTemplateSelectionWrapper } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectDeductions/Forms/TemplateSelection/ProjectDeductionTemplateSelectionWrapper';
import { AddEditProductDeductionWrapper } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/ProjectDeductions/Forms/EditForm/AddEditProjectDeductionWrapper';
import { AddEditCustomProductFormWrapper } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/addEditCustomProduct/AddEditCustomProductFormWrapper';

export interface ProjectsProps {
  onOpenProjectForm: (projectId: number) => void;
}

export const Projects = ({ onOpenProjectForm }: ProjectsProps) => {
  const pushDataLayer = useAnalytics();
  const {
    translate,
    translations: {
      common: { emptyProject, emptyProjectDescription },
    },
  } = useTranslations();
  const dispatch = useDispatch();
  const { leadProjects, isLoading } = useGetLeadProjects();
  const [isCategorySelectionPanelOpen, toggleIsCategorySelectionPanelOpen] = useToggle();
  const [isAddOrEditProductOpen, toggleIsAddOrEditProductOpen] = useToggle();
  const [isAddOrEditCustomProductOpen, toggleIsAddOrEditCustomProductOpen] = useToggle();
  const [isProjectAdditionsOpen, toggleIsProjectAdditionsOpen] = useToggle();
  const [isProjectAdditionTemplateSelectionOpen, toggleIsProjectAdditionTemplateSelectionOpen] = useToggle();
  const [isProjectDeductionsOpen, toggleIsProjectDeductionsOpen] = useToggle();
  const [isProjectDeductionTemplateSelectionOpen, toggleIsProjectDeductionTemplateSelectionOpen] = useToggle();
  const currentProjectId = useSelector(selectCurrentProjectId);

  if (!leadProjects?.length && isLoading) return <CenteredCircularProgress />;
  if (!leadProjects?.length && !isLoading)
    return (
      <Empty title={translate(emptyProject)} description={translate(emptyProjectDescription)} icon={NoProjectsImage} />
    );

  const handleShowAddEditProductPanel =
    (projectId: number) =>
    (categoryId?: number | null, projectProductId?: number, projectProductProductId?: number) => {
      dispatch(setCurrentProjectId(projectId ?? -1));
      dispatch(setCurrentProductCategoryId(categoryId ?? -1));
      dispatch(setCurrentProjectProductId(projectProductId ?? null));
      dispatch(setCurrentProjectProductProductId(projectProductProductId ?? null));
      if (categoryId) {
        toggleIsAddOrEditProductOpen();
      } else {
        toggleIsAddOrEditCustomProductOpen();
      }
    };

  const handleShowAddEditAdditionalPanel = (projectId: number) => (projectAdditionId?: number) => {
    dispatch(setCurrentProjectId(projectId ?? -1));
    dispatch(setCurrentOfferBidId(projectId ?? -1));
    dispatch(setCurrentProjectAdditionId(projectAdditionId ?? null));
    if (projectAdditionId) {
      toggleIsProjectAdditionsOpen();
    } else {
      toggleIsProjectAdditionTemplateSelectionOpen();
    }
  };

  const handleShowAddEditDeductionPanel = (projectId: number) => (projectDeductionId?: number) => {
    dispatch(setCurrentProjectId(projectId ?? -1));
    dispatch(setCurrentOfferBidId(projectId ?? -1));
    dispatch(setCurrentProjectDeductionId(projectDeductionId ?? null));
    if (projectDeductionId) {
      toggleIsProjectDeductionsOpen();
    } else {
      toggleIsProjectDeductionTemplateSelectionOpen();
    }
  };

  const handleNewAddition = () => () => {
    currentProjectId && handleShowAddEditAdditionalPanel(currentProjectId)();
    toggleIsProjectAdditionsOpen();
  };

  const handleNewDeduction = () => () => {
    currentProjectId && handleShowAddEditDeductionPanel(currentProjectId)();
    toggleIsProjectDeductionsOpen();
  };

  return (
    <>
      {leadProjects?.map((project) => (
        <ProjectRow
          key={project.id}
          project={project}
          onShowAddNewProductPanel={() => {
            pushDataLayer({ event: AnalyticEvents.materialAddInitiate, projectType: project.type });
            dispatch(setCurrentOfferBidId(project.id));
            dispatch(setCurrentProductCategoryId(-1));
            dispatch(setCurrentProjectProductId(null));
            dispatch(setCurrentProjectProductProductId(null));
            toggleIsCategorySelectionPanelOpen();
          }}
          onShowAddEditProductPanel={handleShowAddEditProductPanel(project.id)}
          onProjectClick={() => {
            dispatch(setCurrentOfferBidId(project.id));
            onOpenProjectForm(project.id);
          }}
          onShowAddEditAdditionPanel={(projectAdditionId) => {
            pushDataLayer({ event: AnalyticEvents.costAddInitiated, projectType: project.type });
            handleShowAddEditAdditionalPanel(project.id)(projectAdditionId);
          }}
          onShowAddEditDeductionPanel={(projectDeductionId) => {
            pushDataLayer({ event: AnalyticEvents.deductionAddInitiate, projectType: project.type });
            handleShowAddEditDeductionPanel(project.id)(projectDeductionId);
          }}
        />
      ))}
      <Drawer open={isCategorySelectionPanelOpen} onClose={toggleIsCategorySelectionPanelOpen} anchor="right">
        <ProductCategoriesList
          onClose={toggleIsCategorySelectionPanelOpen}
          onCreateCustomProduct={(projectId) => {
            dispatch(setCurrentProjectId(projectId ?? -1));
            toggleIsCategorySelectionPanelOpen();
            toggleIsAddOrEditCustomProductOpen();
          }}
          onSelectCategory={(projectId) =>
            (categoryId, projectProductId?: number, projectProductProductId?: number) => {
              dispatch(setCurrentProjectId(projectId ?? -1));
              dispatch(setCurrentProductCategoryId(categoryId));
              dispatch(setCurrentProjectProductId(projectProductId ?? null));
              dispatch(setCurrentProjectProductProductId(projectProductProductId ?? null));
              toggleIsAddOrEditProductOpen();
              toggleIsCategorySelectionPanelOpen();
            }}
        />
      </Drawer>
      <Drawer open={isAddOrEditProductOpen} onClose={toggleIsAddOrEditProductOpen} anchor="right">
        <EditProjectProductWrapper onClose={toggleIsAddOrEditProductOpen} />
      </Drawer>
      <Drawer open={isAddOrEditCustomProductOpen} onClose={toggleIsAddOrEditCustomProductOpen} anchor="right">
        <AddEditCustomProductFormWrapper onClose={toggleIsAddOrEditCustomProductOpen} />
      </Drawer>
      <Drawer
        open={isProjectAdditionTemplateSelectionOpen}
        onClose={toggleIsProjectAdditionTemplateSelectionOpen}
        anchor="right"
      >
        <ProjectAdditionTemplateSelectionWrapper
          onClickNewAddition={handleNewAddition()}
          onClose={toggleIsProjectAdditionTemplateSelectionOpen}
        />
      </Drawer>
      <Drawer open={isProjectAdditionsOpen} onClose={toggleIsProjectAdditionsOpen} anchor="right">
        <AddEditProjectAdditionWrapper onClose={toggleIsProjectAdditionsOpen} />
      </Drawer>
      <Drawer
        open={isProjectDeductionTemplateSelectionOpen}
        onClose={toggleIsProjectDeductionTemplateSelectionOpen}
        anchor="right"
      >
        <ProjectDeductionTemplateSelectionWrapper
          onClickNewDeduction={handleNewDeduction()}
          onClose={toggleIsProjectDeductionTemplateSelectionOpen}
        />
      </Drawer>
      <Drawer open={isProjectDeductionsOpen} onClose={toggleIsProjectDeductionsOpen} anchor="right">
        <AddEditProductDeductionWrapper onClose={toggleIsProjectDeductionsOpen} />
      </Drawer>
    </>
  );
};

import { LeadPageGoogleMap } from '@components/maps/GoogleMaps/LeadPageGoogleMap/LeadPageGoogleMap';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { Drawer, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import { LeadInfoCards } from '@pages/NewLeads/LeadInfoCards';
import { ContactPersonEdit } from './contactPersonEdit';
import { useUpdateLead } from '@services/api/leads/lead-edit';
import { useGetOffer } from '@services/api/offers/offers';
import { Outlet } from 'react-router-dom';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';

export const ClientTab = () => {
  const pushDataLayer = useAnalytics();
  const { isOfferLocked, lead } = useGetOffer();
  const { updateLeadAsync, isLoading } = useUpdateLead();
  const { openModal: openLeadModal } = useQueryParamsModal(`lead`);
  const {
    isModalOpen: isCustomerModalOpen,
    closeModal: closeCustomerModal,
    openModal: openCustomerModal,
  } = useQueryParamsModal(`customer`);

  const onEditLead = () => {
    pushDataLayer({ event: AnalyticEvents.customerInfo });
    openLeadModal({ mode: `edit` });
  };

  if (!lead)
    return (
      <>
        <Box sx={{ aspectRatio: `16/9`, mb: 2 }}>
          <Skeleton variant="rectangular" height="100%" />
        </Box>
        <Stack direction="row" gap={2} width="100%">
          <Skeleton variant="rectangular" width="100%" height={130} />
          <Skeleton variant="rectangular" width="100%" height={130} />
        </Stack>
      </>
    );

  return (
    <Stack spacing={2}>
      {lead.address && (
        <Box sx={{ aspectRatio: `16/9` }}>
          <LeadPageGoogleMap key={lead.address.street} address={lead.address} />
        </Box>
      )}
      <LeadInfoCards
        onShowEditContactPersonDetails={openCustomerModal}
        onOpenEditLeadForm={onEditLead}
        isOfferLocked={isOfferLocked}
      />
      <Outlet />
      <Drawer open={isCustomerModalOpen} onClose={closeCustomerModal} anchor="right">
        <ContactPersonEdit
          updateContactPerson={(data) => {
            pushDataLayer({ event: AnalyticEvents.contactInfo });
            updateLeadAsync({ ...lead, ...lead.address, contactPerson: data }).then(closeCustomerModal);
          }}
          disableForm={isLoading}
          contactPersonDetails={lead?.contactPerson || null}
          closeModal={closeCustomerModal}
        />
      </Drawer>
    </Stack>
  );
};

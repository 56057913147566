import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@redux/reducers';

export enum NotificationsConnectionState {
  Initial = `Initial`,
  Authenticating = `Authenticating`,
  Authenticated = `Authenticated`,
  Connecting = `Connecting`,
  Connected = `Connected`,
  Failed = `Failed`,
}

export interface SystemSettingsState {
  notificationsConnectionState: NotificationsConnectionState;
}

export const initialState: SystemSettingsState = {
  notificationsConnectionState: NotificationsConnectionState.Initial,
};

const systemSettingsSlice = createSlice({
  name: `system-settings`,
  initialState,
  reducers: {
    setNotificationsConnectionState: (state, action: PayloadAction<NotificationsConnectionState>) => {
      state.notificationsConnectionState = action.payload;
    },
  },
});

export const { setNotificationsConnectionState } = systemSettingsSlice.actions;
export const { reducer: systemSettingsReducer } = systemSettingsSlice;

export const selectNotificationsConnectionState = (state: AppState) =>
  state.systemSettingsReducer.notificationsConnectionState;

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiChevronRight } from '@mdi/js';
import { ActionInformationModal } from '@components/ActionInformationModal/ActionInformationModal';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { RichTextPreview } from '@components/controls/rich-text-preview';
import { useToggle } from '@hooks/useToggle';
import Divider from '@mui/material/Divider';
import { Card } from '@components/Card/Card';
import { useGetSystemMessage } from '@services/api/systemMessage/systemMessage';
import { useEffect, useState } from 'react';
import { SystemMessageDto } from '@generatedTypes/data-contracts';

const SystemMessageBar = ({ systemMessage }: { systemMessage: SystemMessageDto }) => {
  const [messageOpen, toggleMessageOpen] = useToggle();
  const theme = useTheme();
  const [messageVisible, setMessageVisible] = useState(systemMessage.isEnabled);
  const [activeClass, setActiveClass] = useState(systemMessage.isEnabled);

  const {
    translate,
    translations: {
      systemSettings: {
        messagesPage: { systemMessageHeader },
      },
      common: { ok },
    },
  } = useTranslations();

  useEffect(() => {
    if (systemMessage.isEnabled) {
      setMessageVisible(true);
      setTimeout(() => {
        setActiveClass(true);
      }, 0);
    } else {
      setActiveClass(false);
      setTimeout(() => {
        setMessageVisible(false);
      }, 500);
    }
  }, [systemMessage.isEnabled]);

  if (!systemMessage || !messageVisible) {
    return null;
  }

  const { message, title } = systemMessage;

  return (
    <Box
      sx={(theme) => ({
        width: `100%`,
        height: `auto`,
        maxHeight: 0,
        opacity: 0,
        zIndex: 15,
        backgroundColor: theme.palette.background.paper,
        transition: `max-height 0.5s, opacity 0.5s`,

        '&.active': {
          maxHeight: theme.spacing(7),
          height: `auto`,
          opacity: 1,
        },
      })}
      className={activeClass ? `active` : ``}
    >
      <Stack height="100%">
        <Stack direction="row" alignItems="center" justifyContent="center" height="100%">
          <Typography variant="h4">{title}</Typography>
          <IconButton onClick={toggleMessageOpen}>
            <MdiIconWrapper path={mdiChevronRight} color={theme.palette.common.black} />
          </IconButton>
        </Stack>
        <ActionInformationModal
          header={translate(systemMessageHeader)}
          isOpen={messageOpen}
          confirmText={translate(ok)}
          onConfirm={toggleMessageOpen}
          buttonsDivider={false}
        >
          <Card sx={{ border: `none` }}>
            <Stack spacing={2}>
              <Typography variant="h2">{title}</Typography>
              {message && <RichTextPreview richTextAsHtml={message} />}
            </Stack>
          </Card>
        </ActionInformationModal>
        <Divider
          sx={{
            padding: 0,
            margin: 0,
          }}
        />
      </Stack>
    </Box>
  );
};

export const SystemMessage = () => {
  const { systemMessage } = useGetSystemMessage();

  if (!systemMessage) {
    return null;
  }

  return <SystemMessageBar systemMessage={systemMessage} />;
};

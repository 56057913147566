import { Navbar } from '@components/navbar/Navbar';
import React, { useRef } from 'react';
import { assertsReactElementOfColumnItem, ViewType, viewTypeTagParams } from './utils';
import { StickyBar } from '@components/columnLayout/StickyBar';

type ColumnWrapperProps = {
  viewType: ViewType;
  children: React.ReactNode;
  navbarMenuOptions?: React.ReactNode;
  noMenu: boolean;
  sticky?: React.ReactNode;
};

const ColumnWrapper: React.FC<ColumnWrapperProps> = ({ children, viewType, navbarMenuOptions, noMenu, sticky }) => {
  const columnWrapper = useRef<HTMLDivElement>(null);
  const { id, classes } = viewTypeTagParams[viewType];

  return (
    <div id={id} ref={columnWrapper} className={`column ${classes.join(` `)}`}>
      {sticky ? <StickyBar scrollingContainer={columnWrapper}>{sticky}</StickyBar> : null}
      <Navbar viewType={viewType} navbarMenuOptions={navbarMenuOptions} noMenu={noMenu}>
        {children}
      </Navbar>
    </div>
  );
};

type ColumnProps = {
  children?: React.ReactNode;
  active?: string;
  viewType: ViewType;
  navbarMenuOptions?: React.ReactNode;
  noMenu?: boolean;
  sticky?: React.ReactNode;
};

export const Column: React.FC<ColumnProps> = ({
  viewType,
  children,
  active,
  navbarMenuOptions = null,
  noMenu = false,
  sticky,
}) => {
  const childrenList = React.Children.toArray(children);
  const childrenToShow = active
    ? childrenList.filter((child) => {
        assertsReactElementOfColumnItem(child);
        return child.props.label === active;
      })[0]
    : childrenList[0];

  return (
    <ColumnWrapper viewType={viewType} navbarMenuOptions={navbarMenuOptions} noMenu={noMenu} sticky={sticky}>
      {childrenToShow}
    </ColumnWrapper>
  );
};

import { authFetch } from '@services/api/utils';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useCallback, useRef } from 'react';

const authenticateToNotifications = async () => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/notifications/auth`, {
    method: `GET`,
    mode: `cors`,
  });

  if (response.ok) {
    return response.json();
  }

  throw new Error(`Failed to connect to notifications`);
};

type ConnectToNotificationsProps = {
  onError: () => void;
  onOpen: () => void;
  [key: string]: (event: MessageEvent) => void | (() => void) | undefined;
};

export const useNotificationsConnection = ({ onOpen, onError, ...handlers }: ConnectToNotificationsProps) => {
  const es = useRef<EventSource>();

  const connect = useCallback(() => {
    es.current?.close();
    es.current = new EventSource(`${process.env.PROTECTED_API_URL}/notifications/connect`, { withCredentials: true });

    es.current.onopen = () => {
      onOpen?.();
    };

    for (const [key, value] of Object.entries(handlers)) {
      if (value) {
        es.current.addEventListener(key, value);
      }
    }

    es.current.onerror = () => {
      onError?.();
    };
  }, [onOpen, handlers, onError]);

  const disconnect = useCallback(() => {
    es.current?.close();
  }, []);

  return { connect, disconnect };
};

export const useAuthenticateToNotifications = (options?: { onSuccess?: () => void; onError?: () => void }) => {
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: authenticateToNotifications,
    onSuccess: () => {
      options?.onSuccess?.();
    },
    onError: () => {
      options?.onError?.();
    },
  });

  return { authenticate: mutate, authenticateAsync: mutateAsync, isAuhenticating: isLoading };
};

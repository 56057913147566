import Drawer from '@mui/material/Drawer';
import Stack from '@mui/system/Stack';
import { mdiClose } from '@mdi/js';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { SelectDealers } from '@components/navbar/SelectDealers';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { NavLinkItem } from '@components/navbar/NavLinkItem';
import { Route, useRoutesWithTranslations } from '@components/navbar/constants';
import Box from '@mui/material/Box';
import { useTranslations } from '@services/hooks/translations/useTranslations';

import '../../navbar/navbar.css';
import Button from '@mui/material/Button';
import { PageTouchPointWrapper } from '@components/external/TouchPointFeedback/PageTouchPointWrapper';
import { HelpSupportModal } from '@components/HelpSupportModal';
import { useSignOut } from '@services/api/authenticate/signOut';

export interface MenuProps {
  isDrawerOpen: boolean;
  toggleIsDrawerOpen: () => void;
}

export const Menu = ({ isDrawerOpen, toggleIsDrawerOpen }: MenuProps) => {
  const theme = useTheme();
  const currentRoute = useLocation().pathname;
  const { baseRoutes, userSectionRoutes } = useRoutesWithTranslations();
  const { signOut } = useSignOut();
  const {
    translate,
    translations: {
      common: { buttons },
    },
  } = useTranslations();

  const onLogOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const getOptionsForRoutes = useCallback(
    (routes: Route[], profileItems = false) =>
      routes.map(({ name, path }) => (
        <NavLinkItem
          key={path}
          name={name}
          path={path}
          active={path === currentRoute.split(`/`)[0]}
          profileItem={profileItems}
          onActivate={toggleIsDrawerOpen}
        />
      )),
    [currentRoute, toggleIsDrawerOpen],
  );

  return (
    <Drawer open={isDrawerOpen} onClose={toggleIsDrawerOpen} keepMounted>
      <Box className="drawer-content" sx={{ padding: 2 }}>
        <Stack alignItems="end">
          <IconButton sx={{ marginBlockEnd: 1 }} onClick={toggleIsDrawerOpen}>
            <MdiIconWrapper path={mdiClose} color={theme.palette.primary.dark} />
          </IconButton>
        </Stack>
        <Stack className="nav-options nav-options-base">
          <SelectDealers />
        </Stack>
        <Stack className="nav-options nav-options-base" sx={{ marginBlockStart: 3 }}>
          {getOptionsForRoutes(baseRoutes)}
        </Stack>
        <Stack className="nav-options" sx={{ marginBlockStart: 3 }}>
          {getOptionsForRoutes(userSectionRoutes, true)}
          <HelpSupportModal />
          <Button
            sx={{ justifyContent: `flex-start` }}
            variant="text"
            className="nav-item profile-item"
            onClick={onLogOut}
          >
            {translate(buttons.logOut)}
          </Button>
        </Stack>
      </Box>
      <PageTouchPointWrapper />
    </Drawer>
  );
};

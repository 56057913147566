import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiClose } from '@mdi/js';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import Divider from '@mui/material/Divider';
import {
  ContentCard,
  ModalDialog,
  ResponsiveContentWrapper,
} from '@components/ActionInformationModal/ActionInformationModal.styled';
import { Box, Stack } from '@mui/material';

type ActionInformationModalProps = {
  header: string | React.ReactNode;
  onConfirm?: () => void;
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmationDataTestId?: string;
  disabled?: boolean;
  buttonsDivider?: boolean;
};
export const ActionInformationModal: React.FC<ActionInformationModalProps> = ({
  onConfirm,
  header,
  onClose,
  isOpen,
  children,
  confirmText,
  cancelText,
  confirmationDataTestId,
  disabled = false,
  buttonsDivider = true,
}) => {
  const {
    translate,
    translations: {
      common: { buttons },
    },
  } = useTranslations();

  return (
    <ModalDialog open={isOpen} onClose={onClose} fullWidth>
      <Box className="grid-container w980-layout">
        <ResponsiveContentWrapper>
          <ContentCard>
            <DialogActions disableSpacing sx={{ p: 0 }}>
              <Stack direction="row" justifyContent="center" width="100%" sx={{ position: `relative` }}>
                <DialogTitle textAlign="center" variant="h3">
                  {header}
                </DialogTitle>
                {onClose && (
                  <IconButton onClick={onClose} sx={{ position: `absolute`, right: 0 }}>
                    <MdiIconWrapper path={mdiClose} />
                  </IconButton>
                )}
              </Stack>
            </DialogActions>
            <Divider sx={{ margin: 0 }} />
            <DialogContent sx={{ padding: 0 }} dividers={buttonsDivider}>
              {children}
            </DialogContent>
            {buttonsDivider && <Divider sx={{ margin: 0 }} />}
            <DialogActions>
              {onClose && (
                <Button variant="outlined" fullWidth onClick={onClose} disabled={disabled}>
                  {cancelText || translate(buttons.cancel)}
                </Button>
              )}
              {onConfirm && (
                <Button fullWidth onClick={onConfirm} data-testid={confirmationDataTestId} disabled={disabled}>
                  {confirmText || translate(buttons.confirm)}
                </Button>
              )}
            </DialogActions>
          </ContentCard>
        </ResponsiveContentWrapper>
      </Box>
    </ModalDialog>
  );
};

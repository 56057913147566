import ProductFallback from '@assets/svg/Article fallback.svg';
import { ColumnItem } from '@components/columnLayout/utils';
import { LABEL_POSITION } from '@components/controls/react-hook-form-friendly/dumb';
import { Numeric } from '@components/controls/react-hook-form-friendly/smart';
import {
  FormListItemBordered,
  ListItemBorderItem,
} from '@components/controls/react-hook-form-friendly/smart/FormListItemBordered';
import { Switch } from '@components/controls/react-hook-form-friendly/smart/Switch';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { ResultMaterialCount } from '@components/info/ResultMaterialCount';
import { CenteredCircularProgress } from '@components/Loading/CenteredCircularProgress';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import {
  CreateProjectProductRequest,
  ProjectProductOrigin,
  UpdateProjectProductRequest,
} from '@generatedTypes/data-contracts';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { mdiChevronRight, mdiTrashCanOutline } from '@mdi/js';
import { Avatar, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FiltersButtons } from './productsAttributeFilters/filtersButtons';
import { filterProducts } from './productsAttributeFilters/utils/filterProducts';
import { AppliedFilters } from './productsAttributeFilters/utils/types';
import { selectCurrentProjectId, selectCurrentProjectProductId } from '@redux/selectors/lead';
import { useGetProductCategory } from '@services/api/productCategories/productCategories';
import { useDeleteProjectProduct } from '@services/api/projectProducts/projectProductDelete';
import { PutProjectProductProps } from '@services/api/projectProducts/projectProductEdit';
import { useGetProjectProductById } from '@services/api/projectProducts/projectProductGet';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { LinkButton } from '@components/button/LinkButton';
import { setPackageDataToDetailsModal } from '@redux/reducers/slices/leadsPage';
import { Empty } from '@components/Empty/Empty';
import emptyIcon from '@assets/svg/EmptyComponentIcons/EmptyTemplate.svg';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';

const editProjectProductZodObject = {
  quantity: z.number(),
  productId: z.string(),
  enabled: z.boolean(),
};

const editProjectProductSchema = z.object(editProjectProductZodObject);

type EditProjectProductValues = z.infer<typeof editProjectProductSchema>;

const defaultValues: EditProjectProductValues = {
  quantity: 1,
  productId: ``,
  enabled: true,
};

type EditProjectProductProps = {
  closeEditPanel: () => void;
  disableForm?: boolean;
  label?: string;
  onSubmitProjectProduct: (projectProduct: PutProjectProductProps | CreateProjectProductRequest) => void;
  onOpenProductAttributeFilters?: () => void;
  beValidationResults?: ParsedBackendValidationResults | null;
  refetchLeadProjects: () => void;
  filters: AppliedFilters;
  isLoading: boolean;
};

export const EditProjectProduct: ColumnItem<EditProjectProductProps> = (props) => {
  const {
    translate,
    translations: {
      editProduct: { quantity, changeProject, noProductsFound },
      leads: { offer },
    },
  } = useTranslations();
  const { closeEditPanel } = props;

  const dispatch = useDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  const { projectProduct } = useGetProjectProductById();
  const productCategory = useGetProductCategory();
  const currentProjectProductId = useSelector(selectCurrentProjectProductId);
  const { openModal } = useQueryParamsModal(`project`);

  const productUserOrigin = projectProduct?.origin !== ProjectProductOrigin.Template;

  const values = useMemo(
    () =>
      projectProduct
        ? {
            productId: `${projectProduct.productId}`,
            quantity: projectProduct.quantity,
            enabled: !projectProduct.isExcluded,
          }
        : defaultValues,
    [projectProduct],
  );

  const { handleSubmit, control, watch } = useForm<EditProjectProductValues>({
    defaultValues,
    values,
    resolver: zodResolver(editProjectProductSchema),
  });

  const includeProduct = watch(`enabled`);

  const productsToShow = useMemo(
    (): ListItemBorderItem[] =>
      (props.isLoading ? [] : filterProducts(productCategory?.products, props.filters)).map((product) => {
        const productNameLabel = `${product.name}${product.manufacturerName ? `, ${product.manufacturerName}` : ``}`;
        return {
          id: `${product.id}`,
          avatar: <Avatar alt={`${product.name} image`} src={product.imageUrl ?? ProductFallback} variant="square" />,
          subtitle: (
            <Stack direction="column">
              {product.isPackage ? (
                <LinkButton
                  onClick={() => {
                    dispatch(
                      setPackageDataToDetailsModal({
                        id: product.id,
                        name: product.name,
                        categoryName: productCategory?.name ?? ``,
                      }),
                    );
                  }}
                >
                  {productNameLabel}
                </LinkButton>
              ) : (
                <Typography variant="subtitle2">{productNameLabel}</Typography>
              )}
              {product.warranty != undefined && (
                <Typography variant="subtitle1">{`${product.warranty} ${translate(
                  offer.productWarrantySuffix,
                )}`}</Typography>
              )}
            </Stack>
          ),
          backgroundColor: `white`,
        } as ListItemBorderItem;
      }),
    [
      dispatch,
      offer.productWarrantySuffix,
      productCategory?.name,
      productCategory?.products,
      props.filters,
      props.isLoading,
      translate,
    ],
  );

  const onSubmit = useCallback(
    (values: EditProjectProductValues) => {
      const submitObject: UpdateProjectProductRequest | CreateProjectProductRequest = {
        productId: Number(values.productId),
        quantity: values.quantity,
        isExcluded: productUserOrigin ? false : !values.enabled,
      };

      if (currentProjectProductId && currentProjectProductId > 0) {
        props.onSubmitProjectProduct({
          ...submitObject,
          id: currentProjectProductId,
        });
      } else if (projectId) {
        props.onSubmitProjectProduct({
          ...submitObject,
          projectId,
        });
      }
    },
    [currentProjectProductId, productUserOrigin, projectId, props],
  );

  const { deleteProjectProduct, isDeletingProjectProduct } = useDeleteProjectProduct({
    onSuccess: () => {
      closeEditPanel();
      props.refetchLeadProjects();
    },
  });
  const formDisabled = props.disableForm || props.isLoading || isDeletingProjectProduct;

  const deleteProjectProductHandler = useCallback(() => {
    deleteProjectProduct(projectProduct?.id ?? 0);
  }, [projectProduct?.id, deleteProjectProduct]);

  const customHeaderAction = useMemo(() => {
    if (productUserOrigin) {
      return projectProduct ? (
        <IconButton onClick={deleteProjectProductHandler} disabled={formDisabled || props.disableForm}>
          <MdiIconWrapper path={mdiTrashCanOutline} />
        </IconButton>
      ) : null;
    } else {
      return <Switch control={control} name={`enabled`} disabled={formDisabled} />;
    }
  }, [control, projectProduct, deleteProjectProductHandler, formDisabled, productUserOrigin, props.disableForm]);

  return (
    <FormWrapper onCancel={closeEditPanel} onSubmit={handleSubmit(onSubmit)} disabled={props.disableForm}>
      <div className="column gap-36">
        <div className="row space-between align-center">
          <h1 className="bold">{productCategory?.name ?? ``}</h1>
          {customHeaderAction}
        </div>
        <div className="column gap-small">
          {props.isLoading ? (
            <CenteredCircularProgress />
          ) : (
            <>
              <Numeric
                label={translate(quantity)}
                labelPosition={LABEL_POSITION.LEFT}
                min={1}
                control={control}
                name={`quantity`}
                isDisabled={formDisabled || !includeProduct}
                suffix={productCategory?.unit}
              />
              <HorizontalLine />
              <div className="column gap-small">
                <FiltersButtons
                  filters={props.filters}
                  onOpenProductAttributeFilters={props.onOpenProductAttributeFilters}
                />
              </div>
              <div>
                <ResultMaterialCount count={productsToShow.length} />
                {productsToShow.length > 0 ? (
                  <FormListItemBordered
                    control={control}
                    name={`productId`}
                    listItems={productsToShow}
                    disabled={formDisabled || !includeProduct}
                  />
                ) : (
                  <Empty
                    icon={emptyIcon}
                    description={translate(noProductsFound)}
                    callToActionButton={
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.rexelEnergy.ctaBlue,
                          display: `flex`,
                          alignItems: `center`,
                          '&:hover': {
                            cursor: `pointer`,
                          },
                        })}
                        onClick={() => {
                          closeEditPanel();
                          openModal({ projectId });
                        }}
                        variant="h5"
                      >
                        {translate(changeProject)} <MdiIconWrapper path={mdiChevronRight} />
                      </Typography>
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <DevTool control={control} />
    </FormWrapper>
  );
};

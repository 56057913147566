import { useCallback } from 'react';
import { useGetSystemMessage } from '@services/api/systemMessage/systemMessage';
import { useQueryClient } from 'react-query';
import { SYSTEM_MESSAGE } from '@variables/queryKeys';
import { SystemMessageDto } from '@generatedTypes/data-contracts';

export const useSystemMessagesHandlers = () => {
  const { refetchSystemMessage } = useGetSystemMessage();
  const queryClient = useQueryClient();

  const SystemMessageDeactivated = useCallback(() => {
    queryClient.setQueryData([SYSTEM_MESSAGE], (oldData: SystemMessageDto | undefined): SystemMessageDto => {
      return { ...oldData, isEnabled: false };
    });
  }, [queryClient]);

  const handleSystemMessageUpdate = useCallback(() => {
    refetchSystemMessage();
  }, [refetchSystemMessage]);

  return {
    SystemMessageActivated: handleSystemMessageUpdate,
    SystemMessageChanged: handleSystemMessageUpdate,
    SystemMessageDeactivated,
  };
};

import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { resetTokenInStorage } from '@services/hooks/authentication/utils';
import { useQueryClient } from 'react-query';
import { authFetch } from '@services/api/utils';

export async function signOut() {
  return authFetch(`${process.env.PROTECTED_API_URL}/Authenticate/sign-out`, {
    method: `GET`,
    mode: `cors`,
    headers: {
      'Content-Type': `application/json`,
    },
    credentials: `include`,
  })
    .then(async (res) => {
      if (!res.ok) {
        const json = await res.json();
        throw json;
      }
      return;
    })
    .catch((err) => {
      throw new Error(err.errors[0].message);
    });
}

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: signOut,
    onSuccess: () => {
      resetTokenInStorage();
      queryClient.clear();
    },
  });

  return {
    signOut: mutate,
    signOutAsync: mutateAsync,
    isSigningOut: isLoading,
  };
};

import { ProjectType } from '@generatedTypes/data-contracts';
import { CarCharginForm } from './CarChargingForm';
import { SolarEnergyForm } from './SolarEnergyForm';
import { EmptyProjectFormWrapper } from './EmptyProjectForm';
import { EnergyStorageFormWrapper } from '@pages/NewLeads/Projects/EnergyStorageForm';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { SlideInView } from '@components/slideInView/slideInView';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';

export interface NewProjectProps {
  projectType: ProjectType | undefined;
  projectId: number | undefined;
  onClose: () => void;
}

const NewProjectContent = ({ projectType, projectId, onClose }: NewProjectProps) => {
  const { leadProjects } = useGetLeadProjects();
  const project = leadProjects?.find((project) => project.id === Number(projectId));

  const selectedProjectType = project?.type ?? projectType;

  if (selectedProjectType === ProjectType.CarCharging) {
    return <CarCharginForm onClose={onClose} />;
  }

  if (selectedProjectType === ProjectType.SolarEnergy) {
    return <SolarEnergyForm onClose={onClose} />;
  }

  if (selectedProjectType === ProjectType.Empty) {
    return <EmptyProjectFormWrapper onClose={onClose} />;
  }

  if (selectedProjectType === ProjectType.EnergyStorage) {
    return <EnergyStorageFormWrapper onClose={onClose} />;
  }

  return null;
};

export const NewProject = () => {
  const { isModalOpen, options, closeModal } = useQueryParamsModal(`project`);

  const onClose = () => {
    closeModal();
  };

  return (
    <SlideInView isVisible={isModalOpen}>
      <NewProjectContent
        projectType={options.projectType}
        projectId={parseInt(String(options.projectId))}
        onClose={onClose}
      />
    </SlideInView>
  );
};

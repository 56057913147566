import { Dropdown } from '@components/Dropdown/Dropdown';
import { useToggle } from '@hooks/useToggle';
import { mdiExportVariant, mdiPlus } from '@mdi/js';
import MenuItem from '@mui/material/MenuItem';
import { getOfferProductsSpreadsheet, useGetOffer } from '@services/api/offers/offers';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Projects } from './Projects/Projects';
import { useDispatch } from 'react-redux';
import { setCurrentOfferBidId } from '@redux/actions/lead';
import { ProjectType } from '@generatedTypes/data-contracts';
import { Button, Stack } from '@mui/material';
import { getNewProjectOptions } from './utils';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { UserTypeBaseRenderer } from '@components/helpers/UserTypeBaseRenderer';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { useGetLead } from '@services/api/leads/lead-info';
import { NewProject } from '@pages/NewLeads/Projects/NewProject';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';

export const ProjectTab = () => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: { projectNames, addProject, exportOffer },
        },
      },
    },
  } = useTranslations();
  const pushDataLayer = useAnalytics();
  const dispatch = useDispatch();
  const [isNewProjectDropdownOpen, toggleIsNewProjectDropdownOpen] = useToggle();
  const { openModal } = useQueryParamsModal(`project`);

  const { lead } = useGetLead();
  const { offer, isOfferLocked } = useGetOffer();
  const resetCurrentBidId = () => dispatch(setCurrentOfferBidId(0));

  const newProjectsOptions = getNewProjectOptions([
    {
      name: translate(projectNames.carCharging),
      onClick: () => {
        pushDataLayer({ event: AnalyticEvents.addProject, projectType: ProjectType.CarCharging });
        resetCurrentBidId();
        openModal({ projectType: ProjectType.CarCharging });
        toggleIsNewProjectDropdownOpen();
      },
      projectType: ProjectType.CarCharging,
    },
    {
      name: translate(projectNames.solarEnergy),
      onClick: () => {
        pushDataLayer({ event: AnalyticEvents.addProject, projectType: ProjectType.SolarEnergy });
        resetCurrentBidId();
        openModal({ projectType: ProjectType.SolarEnergy });
        toggleIsNewProjectDropdownOpen();
      },
      projectType: ProjectType.SolarEnergy,
    },
    {
      name: translate(projectNames.energyStorage),
      onClick: () => {
        pushDataLayer({ event: AnalyticEvents.addProject, projectType: ProjectType.EnergyStorage });
        resetCurrentBidId();
        openModal({ projectType: ProjectType.EnergyStorage });
        toggleIsNewProjectDropdownOpen();
      },
      projectType: ProjectType.EnergyStorage,
    },
    {
      name: translate(projectNames.emptyProject),
      onClick: () => {
        pushDataLayer({ event: AnalyticEvents.addProject, projectType: ProjectType.Empty });
        resetCurrentBidId();
        openModal({ projectType: ProjectType.Empty });
        toggleIsNewProjectDropdownOpen();
      },
      projectType: ProjectType.Empty,
    },
  ]);

  const onOpenProjectForm = (projectId: number) => {
    openModal({ projectId });
  };

  return (
    <>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Dropdown
            buttonText={translate(addProject)}
            isDropdownOpen={isNewProjectDropdownOpen}
            toggleIsDropdownOpen={toggleIsNewProjectDropdownOpen}
            iconButtonIconPath={mdiPlus}
            disabled={!offer || isOfferLocked}
            styles={{ alignSelf: `flex-start` }}
          >
            {newProjectsOptions.map((option) => {
              return (
                <MenuItem key={option.name} onClick={option.onClick}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Dropdown>
          {isOfferLocked && lead && (
            <UserTypeBaseRenderer exclude={[`Partner`]}>
              <Button
                onClick={() => {
                  pushDataLayer({ event: AnalyticEvents.materialDownload });
                  getOfferProductsSpreadsheet({
                    offerId: lead?.offerId ?? Infinity,
                    offerCity: lead?.address?.city ?? ``,
                    offerStreet: lead?.address?.street ?? ``,
                  });
                }}
                startIcon={<MdiIconWrapper path={mdiExportVariant} />}
                variant="text"
              >
                {translate(exportOffer)}
              </Button>
            </UserTypeBaseRenderer>
          )}
        </Stack>
        <Projects onOpenProjectForm={onOpenProjectForm} />
      </Stack>
      <NewProject />
    </>
  );
};

import { LeadDto } from '@generatedTypes/data-contracts';
import { NullableToUndefindableObject } from '@utils/types/NullableToUndefindable';
import { useGetLead } from '@services/api/leads/lead-info';
import { useUpdateLead } from '@services/api/leads/lead-edit';
import { LeadForm } from './LeadForm/LeadForm';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';
import { useCreateLead } from '@services/api/leads/lead-create';
import { LeadZodSchema } from '@pages/NewLeads/LeadForm/zodSettings';
import { useSelector } from 'react-redux';
import { selectSelectedDealerId } from '@redux/reducers/slices/dealerUser';
import { useNavigate } from 'react-router-dom';

export const AddEditLeadForm = () => {
  const pushDataLayer = useAnalytics();
  const { options, closeModal } = useQueryParamsModal(`lead`);
  const navigate = useNavigate();

  const { lead } = useGetLead();

  const selectedDealerId = useSelector(selectSelectedDealerId);
  const { updateLeadAsync, isLoading, validationErrors: updateValidationErrors } = useUpdateLead();
  const { createLead, validationErrors: createValidationErrors } = useCreateLead();

  if (!lead) return null;

  const { address, ...restData } = lead as NullableToUndefindableObject<LeadDto> & {
    address: NullableToUndefindableObject<LeadDto[`address`]>;
  };

  const onCreateSubmit = async (data: LeadZodSchema) => {
    pushDataLayer({ event: AnalyticEvents.createLead });
    const createdId = await createLead({ ...data, assignedDealerId: selectedDealerId });
    navigate(`/leads/${createdId}`);
  };

  const onUpdateSubmit = (data: LeadZodSchema) => {
    pushDataLayer({ event: AnalyticEvents.customerInfo, customerInfo: data.customerType });
    return updateLeadAsync({ ...data, id: lead.id }).then(closeModal);
  };

  const initialValues = options.mode === `edit` ? { ...address, ...restData } : undefined;
  const onSubmit = options.mode === `edit` ? onUpdateSubmit : onCreateSubmit;

  return (
    <LeadForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      errors={createValidationErrors ?? updateValidationErrors}
    />
  );
};

import { useSearchParams } from 'react-router-dom';
import { ProjectType } from '@generatedTypes/data-contracts';
import { useMemo } from 'react';

const QueryParamsModals = {
  price: {},
  project: { projectType: undefined, projectId: undefined } as {
    projectType?: ProjectType;
    projectId?: string | number | null;
  },
  lead: { mode: undefined as `edit` | `create` | undefined },
  customer: {},
};

function optionIsValid(
  modal: keyof typeof QueryParamsModals,
  option: string,
): option is keyof (typeof QueryParamsModals)[typeof modal] {
  return Object.keys(QueryParamsModals[modal]).includes(option);
}

const queryParamsModalsName = `modal`;
export const useQueryParamsModal = <Modal extends keyof typeof QueryParamsModals>(name: Modal) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get(queryParamsModalsName);
  const options = useMemo(() => {
    const options: Partial<(typeof QueryParamsModals)[Modal]> = {};
    if (mode === name) {
      Object.keys(QueryParamsModals[name]).forEach((option) => {
        const value = searchParams.get(option);
        if (optionIsValid(name, option) && value) {
          options[option as keyof (typeof QueryParamsModals)[Modal]] =
            value as unknown as (typeof QueryParamsModals)[Modal][typeof option];
        }
      });
    }
    return options;
  }, [mode, name, searchParams]);

  const isModalOpen = mode === name;

  const openModal = (options?: (typeof QueryParamsModals)[Modal]) => {
    setSearchParams((params) => {
      params.set(queryParamsModalsName, name);
      if (options) {
        Object.keys(options).forEach((key) => {
          if (optionIsValid(name, key)) {
            const value = options[key];
            if (value) {
              params.set(`${key}`, String(value));
            }
          }
        });
      }
      return params;
    });
  };

  const closeModal = () => {
    setSearchParams((params) => {
      params.delete(queryParamsModalsName);
      Object.keys(QueryParamsModals[name]).forEach((key) => {
        params.delete(key);
      });
      return params;
    });
  };

  const toggleModalOpen = () => {
    if (isModalOpen) {
      closeModal();
    } else {
      openModal();
    }
  };

  return { isModalOpen, closeModal, openModal, toggleModalOpen, options };
};

import { useCallback } from 'react';
import { NotificationsConnectionState, setNotificationsConnectionState } from '@redux/reducers/slices/systemSettings';
import { useDispatch } from 'react-redux';

type UseCommonHandlersProps =
  | {
      handleOpen?: () => void;
      handleError?: () => void;
    }
  | undefined;
export const useCommonHandlers = (props: UseCommonHandlersProps) => {
  const dispatch = useDispatch();

  const onOpen = useCallback(() => {
    props?.handleOpen?.();
    dispatch(setNotificationsConnectionState(NotificationsConnectionState.Connected));
  }, [dispatch, props]);

  const onError = useCallback(() => {
    dispatch(setNotificationsConnectionState(NotificationsConnectionState.Failed));
  }, [dispatch]);

  return { onOpen, onError };
};

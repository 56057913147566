import { useTranslations } from '@services/hooks/translations/useTranslations';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { mdiTrashCanOutline } from '@mdi/js';
import { useTheme } from '@mui/material';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';
import { FormSection } from '@components/forms/MuiFormSection';
import { SystemMessageDto } from '@generatedTypes/data-contracts';

const systemMessagesSchema = z.object({
  title: z.string().min(1),
  message: z.string().optional().nullable(),
});

export type SystemMessagesFormValues = z.infer<typeof systemMessagesSchema>;

type SystemMessagesFormProps = {
  onCancel: () => void;
  onSubmit: (data: SystemMessagesFormValues) => void;
  onDelete?: () => void;
  disabled?: boolean;
  systemMessage?: SystemMessageDto;
};

export const SystemMessagesForm: React.FC<SystemMessagesFormProps> = ({
  onCancel,
  onSubmit,
  onDelete,
  disabled,
  systemMessage,
}) => {
  const {
    translate,
    translations: {
      systemSettings: {
        messagesPage: { header, systemMessageTitle, systemMessageDescription },
      },
    },
  } = useTranslations();

  const { control, handleSubmit } = useForm<SystemMessagesFormValues>({
    resolver: zodResolver(systemMessagesSchema),
    defaultValues: {
      title: systemMessage?.title ?? ``,
      message: systemMessage?.message ?? ``,
    },
  });

  const theme = useTheme();

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={translate(header)}
      disabled={disabled}
      titleAction={onDelete}
      iconPath={onDelete ? mdiTrashCanOutline : undefined}
      iconColor={theme.palette.rexelEnergy.ctaBlue}
    >
      <FormSection>
        <InputWrapper control={control} name="title" label={translate(systemMessageTitle)} isRequired />
        <RichTextInputWrapper label={translate(systemMessageDescription)} control={control} name="message" />
      </FormSection>
    </FormWrapper>
  );
};

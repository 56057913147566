import { PageHeader } from '@components/PageHeader/PageHeader';
import { ColumnLayoutContent } from '@components/Layout/ColumnLayout/ColumnLayoutContent';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { Card } from '@components/Card/Card';
import Divider from '@mui/material/Divider';
import { Drawer, IconButton, Stack, Switch, Typography } from '@mui/material';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiChevronRight, mdiPencil } from '@mdi/js';
import { useToggle } from '@hooks/useToggle';
import { SystemMessagesFormWrapper } from '@pages/systemSettings/messages/SystemMessagesFormWrapper';
import { useGetSystemMessage, useSetSystemMessageActive } from '@services/api/systemMessage/systemMessage';
import { RichTextPreview } from '@components/controls/rich-text-preview';

export const Messages = () => {
  const {
    translate,
    translations: {
      systemSettings: {
        messagesPage: { header, systemMessagesActive, systemMessageHeader },
      },
    },
  } = useTranslations();

  const { setIsActive, isLoading } = useSetSystemMessageActive();
  const { systemMessage } = useGetSystemMessage();

  const onToggleActive = (_: unknown, active: boolean) => {
    setIsActive(active);
  };

  const [isFormOpen, toggleFormOpen] = useToggle();

  return (
    <>
      <ColumnLayoutContent isOpen header={<PageHeader title={translate(header)} />}>
        <Stack spacing={1}>
          <Stack gap={2} className="cards__wrapper">
            <Card
              title={translate(systemMessagesActive)}
              customTitleAction={
                <Switch checked={systemMessage?.isEnabled} disabled={isLoading} onChange={onToggleActive} />
              }
            ></Card>
          </Stack>
          <Stack gap={2} className="cards__wrapper">
            <Card
              title={translate(systemMessageHeader)}
              customTitleAction={
                <IconButton onClick={toggleFormOpen} disabled={isLoading}>
                  <MdiIconWrapper path={mdiPencil} />
                </IconButton>
              }
            >
              <Divider />
              <Stack direction="row" spacing={2} pt={1} pb={1} justifyContent="center">
                <Typography variant="h4">{systemMessage?.title}</Typography>
                <MdiIconWrapper path={mdiChevronRight} />
              </Stack>
              <Divider sx={{ marginBottom: 4 }} />
              <RichTextPreview richTextAsHtml={systemMessage?.message ?? ``} />
            </Card>
          </Stack>
        </Stack>
      </ColumnLayoutContent>
      <Drawer open={isFormOpen} anchor="right" onClose={toggleFormOpen}>
        <SystemMessagesFormWrapper onClose={toggleFormOpen} />
      </Drawer>
    </>
  );
};

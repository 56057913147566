import { FC, ReactNode } from 'react';
import { EmptyStyled, EmptyStyles } from '@components/Empty/Empty.styled';
import { Typography } from '@mui/material';
import { WebTypography } from '@components/Typography/WebTypography';
import { rexelEnergy } from 'src/styles/theme/theme';

interface EmptyProps {
  icon?: string;
  title?: string;
  description?: string;
  callToActionButton?: ReactNode;
}

export const Empty: FC<EmptyProps & EmptyStyles> = ({ icon, title, description, callToActionButton, padding, gap }) => {
  return (
    <EmptyStyled padding={padding} gap={gap}>
      {icon && <img src={icon} alt={title ?? ``} />}
      {title && <WebTypography variant="h3">{title}</WebTypography>}
      {description && <Typography color={rexelEnergy.grey}>{description}</Typography>}
      {callToActionButton}
    </EmptyStyled>
  );
};

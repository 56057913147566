import { ActionWithConfirmation } from '@components/ActionWithConfirmation';
import { PageHeader } from '@components/PageHeader/PageHeader';
import { mdiClose } from '@mdi/js';
import { useTranslations } from '@services/hooks/translations/useTranslations';

export interface DeleteHeaderProps {
  title: string;
  onDelete?: (() => void) | null;
  isPartner?: boolean;
  dataTestId?: string;
}

export const DeleteHeader = ({ title, onDelete, isPartner, dataTestId }: DeleteHeaderProps) => {
  const {
    translate,
    translations: {
      common: {
        deletePopup: { header },
        buttons: { delete: deleteButton },
      },
      partners: {
        list: { removePartnerModalContent },
      },
      leads: {
        details: {
          project: { deleteProjectModalContent },
        },
      },
    },
  } = useTranslations();

  return (
    <PageHeader
      title={title}
      actions={
        onDelete ? (
          <ActionWithConfirmation
            buttonIconPath={mdiClose}
            onConfirm={onDelete}
            confirmationPopupTitle={translate(header)}
            confirmationPopupContent={translate(isPartner ? removePartnerModalContent : deleteProjectModalContent)}
            dataTestId={dataTestId}
            confirmText={translate(deleteButton)}
          >
            {translate(header)}
          </ActionWithConfirmation>
        ) : undefined
      }
    />
  );
};

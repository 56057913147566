import {
  CreateSolarEnergyProjectRequest,
  ProjectType,
  SolarEnergyProjectDto,
  UpdateSolarEnergyProjectRequest,
} from '@generatedTypes/data-contracts';
import { authFetch, getErrorFromFormRequest } from '../utils';
import { useQueryClient } from 'react-query';
import { LEAD, LEADS, OFFER_PRICE_OVERVIEW, PROJECTS, SOLAR_ENERGY_PROJECT } from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useGetLeadProjects } from '../leads/lead-info';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';
import { useCustomQuery } from '@hooks/useCustomQuery';
import { selectCurrentOfferBidId } from '@redux/selectors/lead';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';

const fetchSolarEnergyProject = async (id: number) => {
  if (id < 0) {
    return null;
  }

  return authFetch(`${process.env.PROTECTED_API_URL}/solar-energy-projects/${id}`, {
    method: `GET`,
    mode: `cors`,
  })
    .then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    })
    .then((json) => json as SolarEnergyProjectDto)
    .catch((error) => {
      throw new Error(error.errors[0].Message);
    });
};

const postSolarEnergyProject = async (data: CreateSolarEnergyProjectRequest) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/solar-energy-projects`, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

export interface PutSolarEnergyProject extends UpdateSolarEnergyProjectRequest {
  id: number;
}

const putSolarEnergyProject = async ({ id, ...data }: PutSolarEnergyProject) => {
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/solar-energy-projects/${id}`, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw await getErrorFromFormRequest(response);
  }
};

const deleteSolarEnergyProject = async (projectId: number) => {
  if (projectId === -1) {
    return null;
  }
  const response = await authFetch(`${process.env.PROTECTED_API_URL}/solar-energy-projects/${projectId}`, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const useGetSolarEnergyProject = () => {
  const { options } = useQueryParamsModal(`project`);
  const selectedProjectId = useSelector(selectCurrentOfferBidId);
  const { leadProjects } = useGetLeadProjects();

  const projectIdToUse = options?.projectId ? parseInt(String(options.projectId)) : selectedProjectId;

  const projectType = useMemo(
    () => leadProjects?.find((project) => project.id === projectIdToUse)?.type ?? ProjectType.None,
    [projectIdToUse, leadProjects],
  );
  const { data, refetch, isLoading } = useCustomQuery({
    queryKey: [SOLAR_ENERGY_PROJECT, projectIdToUse],
    queryFn: () => fetchSolarEnergyProject(projectIdToUse),
    enabled: projectIdToUse > 0 && projectType === ProjectType.SolarEnergy,
  });

  return { solarEnergyProjectDetails: data ?? null, refetchSolarEnergyProjectDetails: refetch, isLoading };
};

export const usePostSolarEnergyProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: postSolarEnergyProject,
    onSuccess: () => {
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
    },
  });

  return {
    createSolarEnergyProject: mutate,
    createSolarEnergyProjectAsync: mutateAsync,
    isCreateSolarEnergyProjectLoading: isLoading,
    validationErrors,
  };
};
export const usePutSolarEnergyProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: putSolarEnergyProject,
    onSuccess: () => {
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
    },
  });

  return {
    updateSolarEnergyProject: mutate,
    updateSolarEnergyProjectAsync: mutateAsync,
    isUpdateSolarEnergyProjectLoading: isLoading,
    validationErrors,
  };
};

export const useDeleteSolarEnergyProject = () => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: deleteSolarEnergyProject,
    onSuccess: () => {
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT);
      queryClient.invalidateQueries([LEAD, PROJECTS]);
      queryClient.invalidateQueries(LEADS);
      queryClient.invalidateQueries(LEAD);
      queryClient.invalidateQueries(OFFER_PRICE_OVERVIEW);
    },
  });

  return { deleteSolarEnergyProject: mutate, deleteSolarEnergyProjectAsync: mutateAsync, isLoading };
};
